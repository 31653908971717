/* eslint-disable no-console */

/**
 * @param place The place where it was called
 * @param message The data to be logged
 */
export function consoleError(
  place: string,
  message: string | Object | Error,
): void {
  const timestamp = new Date().toISOString()
  const prefix = `[ERR] ${timestamp}`

  if (message instanceof Error) {
    try {
      console.error(`${prefix}: ${place} - ${JSON.stringify(message)}`)
    } catch {
      console.error(`${prefix}: ${place}`, message)
    }
    return
  }

  if (message instanceof Object) {
    try {
      console.error(`${prefix}: ${place} - ${JSON.stringify(message)}]`)
    } catch {
      console.error(`${prefix}: ${place}`, message)
    }
    return
  }

  console.error(`${prefix}: ${place} - ${message}`)
}

/**
 * @param place The place where it was called
 * @param message The data to be logged
 */
export function consoleLog(place: string, message: string | Object) {
  const timestamp = new Date().toISOString()
  const prefix = `[OUT] ${timestamp}`

  if (message instanceof Object) {
    try {
      console.log(`${prefix}: ${place} - ${JSON.stringify(message)}]`)
    } catch {
      console.log(`${prefix}: ${place}`, message)
    }
    return
  }

  console.log(`${prefix}: ${place} - ${message}`)
}

export type GaEvent =
  | GtmStartEvent
  | GtmSpaEvent
  | UserDetailEvent
  | UserDataSetupEvent
  | EcommerceDetailEvent
  | RemarketingEvent
  | ProductDetailPageViewEvent
  | PageViewEvent
  | AddToCartEvent
  | GtmVirtualPageViewEvent
  | GeneralClickEvent
  | TriggerGroupEvent
  | ProductListView
  | ProductListItemClick
  | AbTestSetup

export type GaProductInfo = {
  // must be config id of product
  id: number
  name: string
  brand: string
  category: string
}

export enum ProductListViewPlacement {
  RecommendedPDP = 'recommendation_product_detail',
}

export type ProductDetailPageViewEvent = Record<string, string> & {
  pageType: 'product detail'
}

export enum TrackingPage {
  ProductDetail = 'product detail',
  HomePage = 'homepage',
  CategoryPage = 'category',
  ManufacturersPage = 'manufacturers',
  ContentPage = 'content',
  FAQ = 'info',
  OrdersPage = 'orders list',
  OrderDetailPage = 'order detail',
  PrintOrderPage = 'print order',
}

export type PageViewEvent = {
  event?: undefined
  pageType: TrackingPage
} & { [key: string]: string }

export type UserDetailEvent = {
  event: GaEventType.UserInfo
  'user.Type': string
  'user.id'?: number
  'gtm.uniqueEventId': number
}

export type UserDataSetupEvent = {
  event: GaEventType.UserDataSetup
  userData: {
    email?: string
    firstName?: string
    lastName?: string
    phone?: string
    city?: string
    postalCode?: string
    country?: string
  }
}

export type AddToCartEvent = {
  event: GaEventType.AddToCart
  prodlist_placement?: ProductListViewPlacement
  // using simple ids of products
  prodlist_ids?: string
  // count starts from 1
  prodlist_position?: number
  ecommerce: {
    currencyCode: string
    add: {
      products: (GaProductInfo & {
        quantity: string
        price: string
        simpleId: number | undefined
      })[]
    }
  }
}

export type RemarketingEvent = {
  event: GaEventType.SetupRemarketing
  google_tag_params: {
    ecomm_pagetype: 'product'
    ecomm_prodid: number
    ecomm_totalvalue: number
  }
}

export type EcommerceDetailEvent = {
  event: GaEventType.ProductVariantsDetail
  ecommerce: {
    currencyCode: string
    detail: {
      products: (GaProductInfo & {
        price: string
        simpleId: number | undefined
      })[]
    }
  }
}

export type GeneralClickEvent = {
  event: GaEventType.Base
  gaEventData: {
    eCat: string
    eAct: string
    eLab: string
  }
  'gtm.uniqueEventId'?: number
}

export type GtmStartEvent = {
  'gtm.start': number
  event: GaEventType.Start
}

export type GtmSpaEvent = {
  event: GaEventType.Spa
  isSPA: 'yes'
  ecommerce: undefined
  pageType: undefined
  pageCategory1: undefined
  pageCategory2: undefined
  pageCategory3: undefined
  pageCategory4: undefined
  google_tag_params: undefined
}

export type GtmVirtualPageViewEvent = {
  event: GaEventType.VirtualPageview
  virtualPV: {
    previousUrl: string
    currentUrl: string
    currentTitle: string
  }
}

export type TriggerGroupEvent = {
  event: GaEventType.TriggerGroup
  'gtm.triggers': string
  'gtm.uniqueEventId': number
}

export type ProductListView = {
  event: GaEventType.ProductListView
  prodlist_placement: ProductListViewPlacement
  prodlist_ids: string
}

export type ProductListItemClick = {
  event: GaEventType.ProductListItemClick
  prodlist_placement: ProductListViewPlacement
  // using simple ids of products
  prodlist_ids: string
  prodlist_position: number
}

export type AbTestSetup = {
  event: GaEventType.AbTestSetup
  [key: string]: string
}

export enum GaEventType {
  Start = 'gtm.js',
  Spa = 'isSPA',
  ProductVariantsDetail = 'gaEeDetail',
  TriggerGroup = 'triggerGroup',
  Base = 'gaEvent',
  AddToCart = 'gaEeAdd',
  SetupRemarketing = 'setupRemarketingData',
  UserInfo = 'user_info',
  UserDataSetup = 'userDataSetup',
  VirtualPageview = 'virtualPageview',
  ProductListView = 'prodlist_view',
  ProductListItemClick = 'prodlist_item_click',
  AbTestSetup = 'abTestSetup',
}

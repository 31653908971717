'use client'

import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

import { useDisclosure } from '@/common/hooks/use-disclosure'
import { FullScreenSpinner } from '@/components/full-screen-spinner'

interface FullScreenSpinnerContextType {
  isVisible: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

export const FullScreenSpinnerContext = createContext(
  {} as FullScreenSpinnerContextType,
)

export const FullScreenSpinnerProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure()

  const contextValue = useMemo(
    () => ({
      onOpen,
      onClose,
      onToggle,
      isVisible: isOpen,
    }),
    [isOpen, onOpen, onClose, onToggle],
  )

  return (
    <FullScreenSpinnerContext.Provider value={contextValue}>
      {children}

      <FullScreenSpinner isVisible={isOpen} />
    </FullScreenSpinnerContext.Provider>
  )
}

export const useFullScreenSpinner = () => useContext(FullScreenSpinnerContext)

import { Scalars, Maybe, IndexedEntity } from '.'

export * from './graphql'
export * from './rest'

// TODO: temporary fix for staging env

export type CategorySearchAmLabel = {
  __typename?: 'CategorySearchAmLabel'
  image?: Maybe<Scalars['String']['output']>
  image_size?: Maybe<Scalars['String']['output']>
  label_text?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  style?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['Int']['output']>
}

export type CategorySearchProduct = {
  __typename?: 'CategorySearchProduct'
  am_labels?: Maybe<Array<Maybe<CategorySearchAmLabel>>>
  default_category?: Maybe<IndexedEntity>
  formatted_price?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  image?: Maybe<Scalars['String']['output']>
  is_saleable?: Maybe<Scalars['String']['output']>
  manufacturer?: Maybe<IndexedEntity>
  name?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['Float']['output']>
  product_url?: Maybe<Scalars['String']['output']>
  rating_summary?: Maybe<Scalars['String']['output']>
  reviews_count?: Maybe<Scalars['String']['output']>
  skus?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  small_image?: Maybe<Scalars['String']['output']>
  thumbnail?: Maybe<Scalars['String']['output']>
}

export type CategoryPageProductsTreeFragment = {
  __typename?: 'CategoryTree'
  categorySearch?: {
    __typename: 'CategorySearchProducts'
    total_count?: number | null
    page_info?: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    } | null
    items?: Array<{
      __typename?: 'CategorySearchProduct'
      formatted_price?: string | null
      id?: number | null
      image?: string | null
      is_saleable?: string | null
      name?: string | null
      price?: number | null
      product_url?: string | null
      rating_summary?: string | null
      reviews_count?: string | null
      skus?: Array<string | null> | null
      small_image?: string | null
      thumbnail?: string | null
      am_labels?: Array<{
        __typename?: 'CategorySearchAmLabel'
        image?: string | null
        image_size?: string | null
        label_text?: string | null
        position?: number | null
        style?: string | null
        type?: number | null
      } | null> | null
      manufacturer?: {
        __typename?: 'IndexedEntity'
        id?: number | null
        name?: string | null
      } | null
      default_category?: {
        __typename?: 'IndexedEntity'
        id?: number | null
        name?: string | null
      } | null
    } | null> | null
  } | null
}

type CategoryPageProductsTreeSearchItems = Pick<
  NonNullable<CategoryPageProductsTreeFragment['categorySearch']>,
  'items'
>

export type CategoryPageProductsTreeSearchItem = NonNullable<
  NonNullable<CategoryPageProductsTreeSearchItems['items']>[0]
>

export type CategorySearchFilterInput = any
export type CategorySearchFiltersQuery = any

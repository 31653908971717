import { CategorySearchFilterInput, InputMaybe, SortEnum } from '@/api'
import {
  AvailableCategorySearchFilters,
  CategoryFilter,
} from '@/modules/category/category-types'
import { SortingValues } from '@/modules/category/parts/filters/filters.const'
import { SearchParams } from '../constants/url-constants'

export const createSearchParams = (
  searchParams: URLSearchParams,
  code: string,
  value: string,
) => {
  const params = new URLSearchParams(searchParams)

  if (params.has(code)) {
    const values = params.getAll(code)
    if (values.includes(value)) {
      params.delete(code)
      values.forEach((v) => {
        if (v !== value) {
          params.append(code, v)
        }
      })
    } else {
      params.append(code, value)
    }
  } else {
    params.append(code, value)
  }

  return params.toString()
}

export const createSearchParamForFiltersExpand = (
  searchParams: URLSearchParams,
  code: 'filters-expanded',
  isExpanded: boolean,
) => {
  const params = new URLSearchParams(searchParams.toString())
  params.set(code, isExpanded.toString())

  return params.toString()
}

export const createSearchParamsForPrice = (
  searchParams: URLSearchParams,
  params: { from: number; to: number },
) => {
  const newParams = new URLSearchParams(searchParams.toString())

  newParams.set('price_from', params.from.toString())
  newParams.set('price_to', params.to.toString())

  return newParams.toString()
}

export const isFilterActive = (
  searchParams: URLSearchParams,
  code: string,
  value: string,
) => {
  const params = new URLSearchParams(searchParams.toString())

  return params.getAll(code).includes(value)
}

export const removeSearchParam = (
  searchParams: URLSearchParams,
  code: string,
) => {
  const params = new URLSearchParams(searchParams.toString())

  params.delete(code)

  return params.toString()
}

export const removeAllFilterParams = (
  searchParams: URLSearchParams,
  availableFilters: AvailableCategorySearchFilters,
) => {
  const params = new URLSearchParams(searchParams.toString())

  params.delete('price_from')
  params.delete('price_to')

  if (availableFilters?.every((filter) => filter?.type === 'range')) {
    params.forEach((_, key) => {
      params.delete(key)
    })
  } else {
    availableFilters?.forEach((filter) => {
      if (filter?.code) {
        params.delete(filter.code)
      }
    })
  }

  return params.toString()
}

export const createFilterInputFromSearchParams = (
  searchParams: URLSearchParams,
) => {
  const filterEntries = searchParams.entries()
  return [...filterEntries].reduce((acc, [key, value]) => {
    if (key === 'price_from') {
      return {
        ...acc,
        price: { ...acc?.price, from: value },
      }
    } else if (key === 'price_to') {
      return {
        ...acc,
        price: { ...acc?.price, to: value },
      }
    } else {
      return {
        ...acc,
        options: [
          ...(acc?.options?.filter((option) => option?.code !== key) || []),
          {
            code: key,
            value: {
              in: acc?.options
                ?.find((option) => option?.code === key)
                ?.value?.in?.concat(value) || [value],
            },
          },
        ],
      }
    }
  }, {} as InputMaybe<CategorySearchFilterInput>)
}

export const filterFirstFiveAndSelectedFilters = (
  filter: CategoryFilter | null,
  searchParams: URLSearchParams,
) =>
  filter?.options
    ?.sort((a, b) => (b?.count ?? 0) - (a?.count ?? 0))
    .slice(0, 5)
    .concat(
      filter?.options
        ?.slice(5)
        .filter((option) =>
          searchParams
            .getAll(filter.code ?? '')
            .includes(option?.value?.toString() ?? ''),
        ),
    )

export const getSortingSearchParamValue = (searchParams: URLSearchParams) => {
  const params = new URLSearchParams(searchParams.toString())
  const sortingSearchParamValue =
    params.get(SearchParams.CategorySorting) ?? SortingValues.Bestseller
  /**
   * User may enter a sorting value manually to the browser search bar. We
   * return a default sort value in this case.
   */
  const isSortingValueValid = Object.values(SortingValues).includes(
    sortingSearchParamValue as SortingValues,
  )

  return isSortingValueValid
    ? sortingSearchParamValue
    : SortingValues.Bestseller
}

export const getSortingSearchParams = ({
  value,
  searchParams,
}: {
  value: SortingValues
  searchParams: URLSearchParams
}) => {
  const params = new URLSearchParams(searchParams.toString())

  params.set(SearchParams.CategorySorting, value)

  if (value === SortingValues.ReviewsCount) {
    params.delete(SearchParams.Filter)
  } else {
    params.set(SearchParams.Filter, 'out')
  }

  return params.toString()
}

export const getSortingInput = (searchParams: URLSearchParams) => {
  const sortingSearchParamValue = getSortingSearchParamValue(searchParams)

  switch (sortingSearchParamValue) {
    case SortingValues.PriceAsc:
      return { price: SortEnum.Asc }
    case SortingValues.PriceDesc:
      return { price: SortEnum.Desc }
    case SortingValues.Bestseller:
      return { bestseller: SortEnum.Desc }

    default:
      return { [sortingSearchParamValue]: SortEnum.Desc }
  }
}

import { useEffect } from 'react'

export const usePreventScroll = (isHidden = true) => {
  useEffect(() => {
    if (isHidden) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [isHidden])
}

export function getWholesalePercentage(maximumRegularPrice, maximumFinalPrice) {
  const wholesaleDiscountPercentage =
    maximumRegularPrice && maximumFinalPrice
      ? (maximumRegularPrice - maximumFinalPrice) / maximumRegularPrice
      : 0

  // round to nearest multiple of 5 (eg: 22 -> 20, 22.5 -> 25)
  const wholesaleDiscountPercentageFormatted = `${
    5 * Math.round((wholesaleDiscountPercentage * 100) / 5)
  }%`

  return {
    wholesaleDiscountPercentage,
    wholesaleDiscountPercentageFormatted,
  }
}

import { HTMLAttributes } from 'react'

import { cn } from '../utils/lib'

export const Spinner = ({
  className,
  ...props
}: HTMLAttributes<HTMLElement>) => (
  <div
    className={cn(
      'h-16 w-16',
      'rounded-full',
      'animate-spin',
      'border-transparent border-[3px] border-t-black z-40',
      className,
    )}
    {...props}
  />
)

export function removeUndefinedValuesFromObject<T extends {}>(
  data: T,
): Required<T> {
  const newData = { ...data }

  Object.keys(newData).forEach(
    (key) => newData[key] === undefined && delete newData[key],
  )

  return newData as Required<T>
}

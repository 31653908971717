'use client'

import { createContext, useContext, PropsWithChildren, RefObject } from 'react'

import { useProductLayout } from './hooks'

export interface ProductUiContextType {
  mobileTopBarHeight: number
  setHeaderRefCallback: (ref: RefObject<HTMLDivElement> | null) => void
}

export const ProductUiContext = createContext<ProductUiContextType>({
  mobileTopBarHeight: 0,
  setHeaderRefCallback: () => {},
})

export const ProductUiContextProvider = ({ children }: PropsWithChildren) => {
  const { mobileTopBarHeight, setHeaderRefCallback } = useProductLayout()

  return (
    <ProductUiContext.Provider
      value={{ mobileTopBarHeight, setHeaderRefCallback }}
    >
      {children}
    </ProductUiContext.Provider>
  )
}

export const useProductUiContext = () => useContext(ProductUiContext)

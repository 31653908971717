'use client'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export interface FlashMessage {
  severity: 'success' | 'warning' | 'error'
  text: string
}

interface FlashMessageWithId extends FlashMessage {
  id: number
}

interface FlashMessagesContext {
  flashMessages: FlashMessageWithId[]
  addFlashMessage: (message: FlashMessage) => void
}

export const FlashMessagesContext = createContext<FlashMessagesContext>({
  flashMessages: [],
  addFlashMessage: () => null,
})

/**
 * Provider that handles all the flash messages in the app, they need to be handled by a single component because of the way how they stack one on each other.
 * And because the flash message could be created from anywhere in the app, the React Context was used.
 * @param children
 * @constructor
 */
export const FlashMessagesProvider = ({ children }: PropsWithChildren) => {
  const [flashMessages, setFlashMessages] = useState<FlashMessageWithId[] | []>(
    [],
  )

  const addFlashMessage = useCallback(
    (newMessage: FlashMessage) => {
      const id = Math.trunc(Math.random() * 10000)
      setFlashMessages((prevMessages) => [
        ...prevMessages,
        { id, ...newMessage },
      ])

      // remove the oldest message from the array after 3s
      setTimeout(() => {
        setFlashMessages(([_, ...rest]) => rest)
      }, 5000)
    },
    [setFlashMessages],
  )

  const contextValue = useMemo(
    () => ({
      addFlashMessage,
      flashMessages,
      setFlashMessages,
    }),
    [flashMessages, addFlashMessage, setFlashMessages],
  )

  return (
    <FlashMessagesContext.Provider value={contextValue}>
      {children}
    </FlashMessagesContext.Provider>
  )
}

export const useFlashMessagesContext = () => useContext(FlashMessagesContext)

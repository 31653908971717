export enum SortingValues {
  New = 'new',
  Bestseller = 'bestsellers',
  ReviewsCount = 'reviews_count',
  RatingSummary = 'rating_summary',
  Saving = 'saving',
  PriceAsc = 'price_asc',
  PriceDesc = 'price_desc',
}

export const SortingOptions = [
  { value: SortingValues.New, label: 'sort.new' },
  { value: SortingValues.Bestseller, label: 'sort.bestseller' },
  { value: SortingValues.ReviewsCount, label: 'sort.reviews_count' },
  { value: SortingValues.RatingSummary, label: 'sort.rating_summary' },
  { value: SortingValues.Saving, label: 'sort.saving' },
  { value: SortingValues.PriceAsc, label: 'sort.price_asc' },
  { value: SortingValues.PriceDesc, label: 'sort.price_desc' },
]

import { HttpMethod, restFetch } from '../fetch'

const SEARCH_ANALYTICS_URL = '/api/rest/V1/gbsearchanalytics/tracking'

// Fragments
interface SearchQueryFragment {
  current_value: string
  entered_at: number
}

interface SearchResultFragment {
  serp_position: number
  uri: string
  display_text: string
}
type ClickResultFragment = Omit<SearchResultFragment, 'serp_position'>

interface SearchSerpFragment {
  // timestamp of display
  displayed_at: number
  // all products
  results: SearchResultFragment[]
  // searched query
  search_query: string
}

interface SearchClickFragment {
  // timestamp of a click
  clicked_at: number
  // clicked product
  result: SearchResultFragment
  // product after the clicked product
  result_after?: ClickResultFragment
  // product before the clicked product
  result_before?: ClickResultFragment
}

// Events
interface SearchEventElasticSearchData {
  event: 'search'
  query: SearchQueryFragment
}

interface ResultsEventElasticSearchData {
  event: 'results'
  // result of: serp.displayed_at - query.entered_at
  offset: number
  query: SearchQueryFragment
  serp: SearchSerpFragment
}

interface ClickEventElasticSearchData {
  event: 'click'
  // result of: click.clicked_at - serp.displayed_at
  offset: number
  click: SearchClickFragment
  query: SearchQueryFragment
  serp: SearchSerpFragment
}

type EventElasticSearchData =
  | SearchEventElasticSearchData
  | ResultsEventElasticSearchData
  | ClickEventElasticSearchData

export const postSearchAnalyticsData = async (query: EventElasticSearchData) =>
  restFetch(SEARCH_ANALYTICS_URL, HttpMethod.POST, {}, query)

export type { SearchResultFragment }

import { getCookie, removeCookie, setCookie } from '@/common/utils/cookie-utils'

const GUEST_TOKEN = 'guest-token'

export const restoreGuestToken = () => {
  const token = getCookie(GUEST_TOKEN)

  if (token) {
    return token
  }

  return undefined
}

export const removeGuestToken = () => {
  removeCookie(GUEST_TOKEN)
}

export const preserveGuestToken = (token: string) => {
  setCookie(GUEST_TOKEN, token, { expires: 1 })
}

import { getCookie } from '@/common/utils/cookie-utils'
import { HttpMethod, restFetch } from '../fetch'

const GET_GUEST_TOKEN_URL = '/api/rest/V1/guest-carts-session'

export const fetchGuestToken = async () => {
  // persistent_quote is a cookie that stores the guest token
  // it is created by magento custom gymbeam module
  const persistentQuote = getCookie('persistent_quote')

  if (persistentQuote) {
    return {
      data: persistentQuote,
    }
  }

  return restFetch<string>(
    GET_GUEST_TOKEN_URL,
    HttpMethod.POST,
    {},
    undefined,
    undefined,
    false,
  )
}

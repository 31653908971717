import { getCookie, removeCookie, setCookie } from '@/common/utils/cookie-utils'

const CUSTOMER_SEGMENT = 'customerSegment'

export const restoreCustomerSegment = () => {
  const segment = getCookie(CUSTOMER_SEGMENT)

  return segment
}

export const removeCustomerSegment = () => {
  if (restoreCustomerSegment()) {
    removeCookie(CUSTOMER_SEGMENT)
  }
}

export const preserveCustomerSegment = (segment: string) => {
  setCookie(CUSTOMER_SEGMENT, String(segment))
}

'use client'

import { captureException } from '@sentry/nextjs'
import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { CustomerFragment, fetchGuestToken } from '@/api'
import { useHandleCustomerData } from './hooks'
import { restoreCustomerToken } from './utils'
import { removeCookie } from '@/common/utils/cookie-utils'
import { consoleError } from '@/common/utils/console'
import { StoreCodeType } from '@/common/types'

interface AuthContext {
  loaded: boolean
  guestToken: string | undefined
  customerToken: string | undefined
  customerData: CustomerFragment | undefined
  logout: () => void
}

export const AuthContext = createContext<AuthContext>({
  loaded: false,
  guestToken: undefined,
  customerToken: undefined,
  customerData: undefined,

  logout: () => ({}),
})

/**
 * The Provider handles users token and customer data
 * @param children
 * @constructor
 */
export const AuthContextProvider = ({
  storeCode,
  children,
}: { storeCode: StoreCodeType } & PropsWithChildren) => {
  const [guestToken, setGuestToken] = useState<string | undefined>(undefined)
  const customerToken = useMemo(() => restoreCustomerToken(), [])

  const fetchNewGuestToken = useCallback(async () => {
    try {
      const res = await fetchGuestToken()

      if (res.error || !res.data) {
        consoleError('auth-context.tsx [try]', 'Failed to fetch guest token')
        captureException(res.error, {
          level: 'error',
          extra: {
            place: 'auth-context.tsx [fetchNewGuestToken][catch]',
            details: {
              message: 'Failed to fetch guest token',
              data: res.data,
              storeCode,
              customerToken,
              guestToken,
            },
          },
        })
      } else {
        setGuestToken(res.data)
        removeCookie('customerToken')
        removeCookie('customerSegment')
      }
    } catch (e) {
      consoleError('auth-context.tsx [catch]', 'Failed to fetch guest token')
      captureException(e, {
        level: 'error',
        extra: {
          place: 'auth-context.tsx [fetchNewGuestToken][catch]',
          details: {
            message: 'Failed to fetch guest token',
            storeCode,
            customerToken,
            guestToken,
          },
        },
      })
    }
  }, [customerToken, guestToken, storeCode])

  useEffect(() => {
    if (!guestToken && !customerToken) {
      fetchNewGuestToken()
    }
  }, [customerToken, fetchNewGuestToken, guestToken])

  const authLogout = () => {
    setGuestToken(undefined)
  }

  const { loaded, customerData } = useHandleCustomerData({
    customerToken,
    guestToken: guestToken || undefined,
    logout: authLogout,
  })

  const contextValue: AuthContext = useMemo(
    () => ({
      // actions
      logout: authLogout,
      // state
      loaded,
      guestToken,
      customerToken,
      customerData,
    }),
    [loaded, guestToken, customerToken, customerData],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

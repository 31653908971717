import { EstimatedDateOfArrivalType } from '@/api'

export const checkEstimatedDateOfArrival = (
  estimatedDateOfArrival: EstimatedDateOfArrivalType | null | undefined,
): string | null | undefined => {
  const { timestamp, formatted_date: formattedDate } =
    estimatedDateOfArrival ?? {}
  if (!timestamp) return undefined

  const dateNow = new Date()
  const expectedArrivalDate = new Date(Number(timestamp))

  if (expectedArrivalDate.getTime() > dateNow.getTime()) {
    return undefined
  }

  return formattedDate
}

type MinifyOptions = {
  preserveComments?: boolean
  preserveAliases?: boolean
}

/**
 * Minifies GraphQL operations except mutations
 * Preserves formatting for mutations for better debugging
 */
export function minifyGraphQLOperation(
  query: string,
  options?: MinifyOptions,
): string {
  const isMutation = query.trim().toLowerCase().startsWith('mutation')
  if (isMutation) return query

  const withoutComments =
    options?.preserveComments ?? false
      ? query
      : query.replace(/#.*$/gm, '').replace(/\/\*[\s\S]*?\*\//gm, '')

  const aliasPattern =
    options?.preserveAliases ?? true
      ? /([a-zA-Z0-9_]+\s*:\s*)?[a-zA-Z0-9_]+/g
      : /[a-zA-Z0-9_]+/g

  // First, protect string literals
  const stringLiterals: string[] = []
  const withProtectedStrings = withoutComments.replace(
    /"([^"\\]|\\.)*"/g,
    (match) => {
      stringLiterals.push(match)
      return `__STRING_${stringLiterals.length - 1}__`
    },
  )

  let processedQuery = withProtectedStrings
  if (options?.preserveAliases === false) {
    processedQuery = processedQuery.replace(
      /([a-zA-Z0-9_]+)\s*:\s*([a-zA-Z0-9_]+)/g,
      '$2',
    )
  }

  const minified = processedQuery
    // Remove whitespace between operators
    .replace(/\s+/g, ' ')
    // Remove spaces around punctuation/braces
    .replace(/\s*({|}|\[|\]|,|:|\(|\))\s*/g, '$1')
    // Preserve spaces for aliases if needed
    .replace(aliasPattern, (match) => match.trim())
    // Remove leading/trailing whitespace
    .trim()

  // Restore string literals
  return minified.replace(
    /__STRING_(\d+)__/g,
    (_, index) => stringLiterals[parseInt(index)],
  )
}

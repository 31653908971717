'use client'

import { Breakpoints } from 'src/config/tailwind/workspace-tailwind-config'
import { useWindowSize } from './use-window-size'

export type Breakpoint = keyof typeof Breakpoints

export enum Direction {
  Min = 'MIN',
  Max = 'MAX',
}

type UseBrowserMediaQueryProps = {
  direction?: Direction
  breakpoint: Breakpoint
}

export const BreakpointValues = Object.entries(Breakpoints).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: parseInt(value) }),
  {} as { [K in keyof typeof Breakpoints]: number },
)

/**
 * @param breakpoint is a predefined device breakpoint: Lg, Md, Sm
 * @param direction is either css media query `max-width` or `min-width`
 */
export const useBrowserMediaQuery = ({
  breakpoint,
  direction = Direction.Max,
}: UseBrowserMediaQueryProps) => {
  const { width: windowWidth } = useWindowSize()

  const isBelowBreakpoint = direction === Direction.Max

  return (
    typeof windowWidth === 'number' &&
    (isBelowBreakpoint
      ? windowWidth < BreakpointValues[breakpoint]
      : windowWidth > BreakpointValues[breakpoint])
  )
}

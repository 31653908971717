export enum ReviewsFilterType {
  Local = 'local',
  Verified = 'verified',
  Positive = 'positive',
  Negative = 'negative',
  Translated = 'translated',
  None = 'none',
}

export const reviewFilters: ReviewsFilterType[] = [
  ReviewsFilterType.Local,
  ReviewsFilterType.Verified,
  ReviewsFilterType.Positive,
  ReviewsFilterType.Negative,
  ReviewsFilterType.Translated,
]

import ReactDOM from 'react-dom'

import { Spinner } from './spinner'
import { usePreventScroll } from '../hooks/use-prevent-scroll'

type FullScreenSpinnerProps = {
  isVisible?: boolean
}

export const FullScreenSpinner = ({ isVisible }: FullScreenSpinnerProps) => {
  usePreventScroll(isVisible)

  if (!isVisible) {
    return null
  }

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-white">
      <Spinner />
    </div>,
    document.body,
  )
}

import { HttpMethod, restFetch } from '../fetch'

const GET_GEO_LOCATION = '/rest/V1/geolocation'

export interface GeoLocationData {
  recommended_store: boolean
  description?: string
  redirect_button_title?: string
  stay_button_title?: string
  recommended_stores?: RecommendedStore[]
}

export interface RecommendedStore {
  url: string
  name: string
}

export const getGeoLocation = () =>
  restFetch<[GeoLocationData]>(GET_GEO_LOCATION, HttpMethod.POST)

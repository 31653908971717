import { getWholesalePercentage } from '../get-wholesale-percentage'
import {
  ProductPriceData,
  ProductPriceInputData,
} from './obsolete-get-price.types'

export function obsoloteGetPrice(
  productPrice: ProductPriceInputData | undefined | null,
): ProductPriceData {
  const { price_range: priceRange, msrp_price: msrpPrice } = productPrice ?? {}
  const { maximum_price: maximumPrice } = priceRange ?? {}
  const { minimum_price: minimumPrice } = priceRange ?? {}
  const { final_price: maximumFinalPrice, regular_price: maximumRegularPrice } =
    maximumPrice ?? {}

  const { wholesaleDiscountPercentage, wholesaleDiscountPercentageFormatted } =
    getWholesalePercentage(maximumRegularPrice?.value, maximumFinalPrice?.value)

  const { final_price: minimumFinalPrice } = minimumPrice ?? {}

  const showRegularPrice =
    (maximumRegularPrice?.value ?? 0) > (maximumFinalPrice?.value ?? 0)

  const allFinalPricesAreEqual =
    (minimumFinalPrice?.value ?? 0) === (maximumFinalPrice?.value ?? 0)

  return {
    showRegularPrice,
    finalPrice: maximumFinalPrice,
    recommendedPrice: showRegularPrice
      ? maximumRegularPrice
      : {
          value: msrpPrice,
          currency: maximumRegularPrice?.currency,
        },
    allFinalPricesAreEqual: allFinalPricesAreEqual,
    wholesaleDiscountPercentageFormatted,
    wholesaleDiscountPercentage,
  }
}

export const arePricesDifferentInVariants = (
  prices: (number | undefined | null)[],
) => {
  const uniquePrices = new Set(prices.filter(Boolean))

  return uniquePrices.size > 1
}

import {
  getCookie,
  removeCookie,
  CookieKeys,
} from '@/common/utils/cookie-utils'

export const restoreCustomerToken = () => {
  const token = getCookie(CookieKeys.CUSTOMER_TOKEN)

  return token
}

export const removeCustomerToken = () => {
  if (restoreCustomerToken()) {
    removeCookie(CookieKeys.CUSTOMER_TOKEN)
  }
}

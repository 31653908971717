import { useEffect, useState } from 'react'
import md5 from 'md5'

import { CustomerFragment, useCustomerBaseInfoQuery } from '@/api'
import { preserveCustomerSegment } from '../utils'

interface HandleCustomerDataProps {
  customerToken: string | undefined
  guestToken: string | undefined
  logout: () => void
}

/**
 * hook that handles the customer data, it also works as a signal for auth load to be completed.
 * @param customerToken
 * @param guestToken
 * @param logout
 */
export const useHandleCustomerData = ({
  customerToken,
  guestToken,
  logout,
}: HandleCustomerDataProps) => {
  const [loaded, setLoaded] = useState(false)
  const [customerData, setCustomerData] = useState<CustomerFragment>()

  const { isFetched, data, error } = useCustomerBaseInfoQuery(
    {},
    {
      enabled: !!customerToken,
    },
  )

  useEffect(() => {
    if (guestToken) {
      setLoaded(true)
    }
    if (customerToken && isFetched) {
      if (error) {
        /*
        this happens if the customerToken is invalid,
        in that's the case the logout function resets all the necessary tokens and state data
        this triggers the useHandleToken hook which sets the guestToken.
         */
        logout()
      } else {
        const customerData = data?.customer ?? undefined

        // for caching purposes, store md5 hash of customer group name as cookie customerSegment
        const customerGroupName = customerData?.group_code
        if (customerGroupName) {
          preserveCustomerSegment(md5(customerGroupName))
        }

        setCustomerData(customerData)
        setLoaded(true)
      }
    }
  }, [customerToken, data?.customer, error, guestToken, isFetched, logout])

  return { loaded, customerData }
}
